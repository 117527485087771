<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'CoreGroupbuyingsList',
              }"
              >團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>{{
              isEdit ? "編輯團購" : "我要開團"
            }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="email-wrapper wrapper">
      <div class="row align-items-stretch">
        <div class="mail-sidebar d-none d-lg-block col-md-2 pt-3 bg-white">
          <div class="menu-bar">
            <ul class="menu-items">
              <li class="compose mb-3" v-if="!isCreate">
                <button class="btn btn-primary btn-block" @click="startGroupbuying()" :disabled="!isEdit || !isDraftGroupbuying">{{
                    isEdit ? "發起團購" : "編輯團購"
                  }}</button>
              </li>
              <li :class="step == '' ? 'active' : ''" :style="isCreate ? '' : 'cursor: pointer;'" @click="goToStep('')">
                <a>團購設定</a>
                <i :class="`fa ${groupbuying ? 'fa-check-circle' : 'fa fa-check-circle-o'}`"
                  :style="groupbuying ? 'color:green;' : ''"
                  aria-hidden="true"></i>
              </li>
              <li :class="step == '#product' ? 'active' : ''" :style="isCreate ? 'cursor: not-allowed;' : 'cursor: pointer;'" @click="goToStep('#product')">
                <a>商品設定</a>
                <i :class="`fa ${(groupbuying && groupbuying.sku) ? 'fa-check-circle' : 'fa fa-check-circle-o'}`"
                  :style="(groupbuying && groupbuying.sku) ? 'color:green;' : ''"
                  aria-hidden="true"></i>
              </li>
              <li :class="step == '#product-setting' ? 'active' : ''" :style="isCreate || isNotSettingProduct  ? 'cursor: not-allowed;' :'cursor: pointer;'" @click="goToStep('#product-setting')">
                <a>商品圖片</a>
                <i :class="`fa ${productImages.length ? 'fa-check-circle' :'fa fa-check-circle-o' }`"
                  :style="productImages.length ? 'color:green;' : ''"
                  aria-hidden="true"></i>
              </li>
              <li :class="step == '#advanced-setting' ? 'active' : ''" :style="isCreate || isNotSettingProduct ? 'cursor: not-allowed;' :'cursor: pointer;'" @click="goToStep('#advanced-setting')">
                <a>進階設定</a>
              </li>
              <li :class="step == '#status' ? 'active' : ''" :style="isCreate || isNotSettingProduct ? 'cursor: not-allowed;' :'cursor: pointer;'" @click="goToStep('#status')">
                <a>狀態設定</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="mail-view d-none d-md-block col-md-9 col-lg-10 bg-white">
          <b-card>
            <section class="mb-5" v-if="step == ''">
              <h4 class="mb-2 font-weight-bold">
                團購設定
              </h4>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="團購名稱"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.title"
                  :disabled="!isEdit && !isCreate"
                  :state="v$.form.title.$error ? false : null"
                ></b-form-input>
                <b-form-invalid-feedback :state="!v$.form.title.$error">
                  團購名稱為必填
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="團購說明"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  rows="5"
                  v-model="form.description"
                  :disabled="!isEdit && !isCreate"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="下單說明"
                label-for="order_message"
              >
                <b-form-input
                  id="order_message"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.order_message"
                  :disabled="!isEdit && !isCreate"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="下單完成說明"
                label-for="success_message"
              >
                <b-form-input
                  id="success_message"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.success_message"
                  :disabled="!isEdit && !isCreate"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                content-cols="12"
                content-cols-lg="6"
                label="開始時間"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="Select Date"
                      v-model="form.start_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      :disabled="(!isEdit && !isStatusPrepared || !isDraftGroupbuying) && !isCreate"
                      :disabledDates="disabledDates"
                    ></datepicker>
                    <b-form-invalid-feedback
                      :state="!v$.form.start_at.date.required.$error"
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>

                  <div class="ml-2 w-50">
                    <vue-timepicker
                      format="HH:mm"
                      v-model="form.start_at.time"
                      :input-class="[
                        'form-control',
                        {
                          'is-invalid':
                            v$.form.start_at.time.HH.$error ||
                            v$.form.start_at.time.mm.$error,
                        },
                      ]"
                      :disabled="(!isEdit && !isStatusPrepared || !isDraftGroupbuying) && !isCreate"
                    ></vue-timepicker>
                    <b-form-invalid-feedback
                      :state="
                        !v$.form.start_at.time.HH.$error ||
                        !v$.form.start_at.time.mm.$error
                      "
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                content-cols="12"
                content-cols-lg="6"
                label="截止時間"
                description="*達截止時間系統將自動結團"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="Select Date"
                      v-model="form.end_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      :disabled="!isEdit && !isCreate"
                      :input-class="{
                        'is-invalid': v$.form.start_at.$invalid,
                      }"
                      :disabledDates="disabledDates"
                    ></datepicker>
                    <b-form-invalid-feedback :state="!v$.form.end_at.date.$error">
                      此欄位為必填
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      :state="!v$.form.start_at.date.beforeEndAt.$invalid"
                    >
                      截止時間必須大於開團時間
                    </b-form-invalid-feedback>
                  </div>

                  <div class="ml-2 w-50">
                    <vue-timepicker
                      :disabled="!isEdit && !isCreate"
                      :input-class="[
                        'form-control',
                        {
                          'is-invalid':
                            v$.form.start_at.time.HH.$error ||
                            v$.form.start_at.time.mm.$error ||
                            v$.form.start_at.date.beforeEndAt.$invalid,
                        },
                      ]"
                      v-model="form.end_at.time"
                    ></vue-timepicker>
                    <b-form-invalid-feedback
                      :state="
                        !v$.form.end_at.time.HH.$error ||
                        !v$.form.end_at.time.mm.$error
                      "
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-form-group>
              <div v-if="isEdit || isCreate" class="mt-5">
                <br>
                <br>
                <br>
                <b-button variant="primary" class="mr-5" @click="backToList()">取消</b-button>
                <b-button variant="primary" @click="submitGroupbuying()">儲存</b-button>
              </div>
            </section>
            <section class="mb-5" v-if="step == '#product'">
              <h4 class="mb-2 font-weight-bold">
                商品設定
              </h4>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="團購名稱"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.title"
                  :disabled="step != 1"
                ></b-form-input>
              </b-form-group>
              <div class="row ml-1">
                <b-form-radio class="mt-2 col-lg-2 col-6" v-model="selected_product" :aria-describedby="selected_product" name="selected_product" value="1">
                  <div class="s-radio-text">選擇商品</div>
                </b-form-radio>
                <div class="col-lg-9 col-6">
                  <b-form-select
                    id="prodcut"
                    class="form-control form-control-sm mt-2 col-12 col-xl-6"
                    v-model="product_sku_id"
                    :disabled="(!isEdit && !isCreate) || !isDraftGroupbuying || isCreateProduct"
                  >
                    <b-form-select-option :value="null" disabled>
                      請選擇商品編號
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="sku in skuList"
                      :value="sku.id"
                      :key="sku.id"
                    >
                      {{ getSkuOptionName(sku) }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-checkbox
                    class="mb-2 mt-2 mr-sm-2 mb-sm-0"
                    id="sync_to_cresclab"
                    v-model="edit_product"
                    name="sync_to_cresclab"
                    :disabled="!isEdit || isCreateProduct || !this.product_sku_id || !$permissions.has($permissions.consts.CORE_GROUPBUYING_PRODUCT_MODIFY)"
                  >修改商品內容</b-form-checkbox>
                </div>
              </div>
              <b-form-radio class="mb-2" v-model="selected_product" :aria-describedby="selected_product" name="selected_product" value="2" :disabled="!isDraftGroupbuying || !$permissions.has($permissions.consts.CORE_GROUPBUYING_PRODUCT_CREATE)">
                <div class="s-radio-text">新增商品</div>
              </b-form-radio>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="商品名稱"
                label-for="product_title"
              >
                <b-form-input
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.product.title"
                  :disabled="!isEdit || !isDraftGroupbuying || !(isEditProduct || isCreateProduct)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="商品規格"
                label-for="sku_name"
              >
                <b-form-input
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.sku.sku_name"
                  :disabled="!isEdit || !isDraftGroupbuying || !(isEditProduct || isCreateProduct)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="商品說明"
                label-for="product_description"
              >
                <b-form-textarea
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.product.description"
                  :disabled="!isEdit || !isDraftGroupbuying || !(isEditProduct || isCreateProduct)"
                ></b-form-textarea>
              </b-form-group>
              <!-- <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="商品庫存"
                label-for="product_sku_quantity"
                >
                <b-form-input
                  type="number"
                  v-model="form.sku.quantity"
                  :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                  :disabled="!isEdit || !isDraftGroupbuying || !(isEditProduct || isCreateProduct)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="商品庫存"
                label-for="product_sku_quantity"
                >
                <b-form-input
                  type="number"
                  v-model="form.sku.quantity"
                  :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                  :disabled="!isEdit || !isDraftGroupbuying || !(isEditProduct || isCreateProduct)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="商品價格"
                label-for="product_price_show"
              >
                <b-form-radio-group
                  v-model="product_price_show"
                  :options="product_price_options"
                  :disabled="!isEdit || !isDraftGroupbuying || !(isEditProduct || isCreateProduct)"
                  class="mb-3"
                  value-field="item"
                  text-field="name"
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="原價"
                label-for="product_original_price"
              >
                <b-form-input
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.product.original_price"
                  type="number"
                  :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                  :disabled="!isEdit || !isDraftGroupbuying || !(isEditProduct || isCreateProduct) || !product_price_show"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="售價"
                label-for="product_price"
              >
                <b-form-input
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.product.price"
                  type="number"
                  :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                  :disabled="!isEdit || !isDraftGroupbuying || !(isEditProduct || isCreateProduct) || !product_price_show"
                ></b-form-input>
              </b-form-group> -->
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="商品店內碼(選填）"
                label-for="product_sku_sku_outer_code"
              >
                <b-form-input
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.sku.sku_outer_code"
                  :disabled="!isEdit || !isDraftGroupbuying || !(isEditProduct || isCreateProduct)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="商品條碼(選填）"
                label-for="product_sku_sku_serial_number"
              >
                <b-form-input
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  v-model="form.sku.sku_serial_number"
                  :disabled="!isEdit || !isDraftGroupbuying || !(isEditProduct || isCreateProduct)"
                ></b-form-input>
              </b-form-group>
              <div v-if="isEdit && isDraftGroupbuying">
                <b-button variant="primary" class="mr-5" @click="backToList()">取消</b-button>
                <b-button variant="primary" @click="submitProduct()">儲存</b-button>
              </div>
            </section>
            <section class="mb-5" v-if="step == '#product-setting'">
              <h4 class="mb-2 font-weight-bold">
                商品圖片
              </h4>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="團購名稱"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.title"
                  :disabled="step != 1"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="商品名稱"
                label-for="product.title"
              >
                <b-form-input
                  id="product.title"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="form.product.title"
                  :disabled="step != 2"
                ></b-form-input>
              </b-form-group>
              <div class="mb-2 text-danger">商品照片上傳最少需1張，上限為10張</div>
              <div class="mb-2 text-danger">建議尺寸 1400px X 1400px</div>
                <input class="s-file-input" type="file" name="image" accept="image/*" ref="input" @change="loadImage" :multiple="true"/>

                <div v-if="productImages.length < 10" class="mb-5">
                  <div class="s-cropper-inner">
                    <div style="position: relative;">
                      <button
                        class="btn s-trigger-btn"
                        :disabled="!canClick"
                        @click="openFileSelector"
                      >
                        <template v-if="canClick">
                          <div class="add-image-icon">＋</div>
                          <div>新增</div>
                        </template>
                        <div v-if="!canClick" v-html="uploadingText()"></div>
                      </button>
                    </div>
                  </div>
                </div>

                <VueDraggable
                  class="list-unstyled s-image-list mb-5"
                  handle=".s-draggable"
                  tag="ul"
                  v-model="productImages"
                  :move="checkMove"
                  @end="dragEnd"
                >
                  <li
                    class="s-image-list-item"
                    :key="i"
                    v-for="(image, i) in productImages"
                  >
                    <div class="s-draggable">
                      <img
                        class="s-cropped-image"
                        :src="image.pic_url"
                      />
                      <button
                        class="btn btn-secondary s-delete-btn"
                        type="button"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" style="width: 22px;">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                        </svg>
                      </button>
                    </div>

                    <!-- 發現手機板 chrome 的 click 似乎與 draggable 處理 event 衝突, 先簡單用 touchstart 解掉 -->

                    <div class="s-list-item-btn-menu">
                      <b-button
                        :disabled="!canClick"
                        variant="outline-danger"
                        @click="deleteImage(i)"
                      >刪除</b-button>
                    </div>
                  </li>
                </VueDraggable>
            </section>
            <section class="mb-5" v-if="step == '#advanced-setting'">
              <h4 class="mb-2 font-weight-bold">
                進階設定
              </h4>
              <h5 class="mb-2 font-weight-bold">
                前台顯示
              </h5>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="顯示累積數量"
              >
                <b-form-radio-group
                  id="show_total_amount"
                  v-model="form.show_total_amount"
                  name="show_total_amount"
                  :disabled="!isEdit"
                >
                  <b-form-radio :value="true">顯示</b-form-radio>
                  <b-form-radio :value="false">不顯示</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="手動調整數量"
              >
                <b-form-input
                  id="adjust_quantity"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  style="width: 80px"
                  v-model="form.adjust_quantity"
                  :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                  :disabled="!isEdit"
                ></b-form-input>
              </b-form-group>
              <h5 class="mb-2 font-weight-bold">
                下單設定
              </h5>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="團購數量上限"
                label-for="max_quantity"
              >
                <b-form-input
                  id="max_quantity"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  style="width: 80px"
                  v-model="form.max_quantity"
                  :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                  :disabled="!isEdit && !isStatusPrepared"
                ></b-form-input>
                （ 0 為不設限 ）
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="可否重複下單"
              >
                <b-form-radio-group
                  id="can_order_again"
                  v-model="form.can_order_again"
                  name="can_order_again"
                  :disabled="!isEdit || !isDraftGroupbuying"
                >
                  <b-form-radio value="1">可重複下單</b-form-radio>
                  <b-form-radio value="0">不可重複下單</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="每人購買上限數"
                label-for="max_purchase_limit"
              >
                <b-form-input
                  id="max_purchase_limit"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  style="width: 80px"
                  v-model="form.max_purchase_limit"
                  :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                  :disabled="!isEdit && !isStatusPrepared || form.can_order_again == '0' "
                ></b-form-input>
                （ 0 為不設限 ）
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="數量選擇"
                v-if="false"
              >
                <b-form-radio-group
                  id="need_choose_number"
                  v-model="form.need_choose_number"
                  name="need_choose_number"
                  :disabled="!isEdit"
                >
                  <b-form-radio :value="true">需選擇數量</b-form-radio>
                  <b-form-radio :value="false">不需選擇數量</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="每人最高購買數"
                label-for="once_max_quantity"
                v-if="false"
              >
                <b-form-input
                  id="once_max_quantity"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  style="width: 80px"
                  v-model="form.once_max_quantity"
                  :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                  :disabled="!isEdit && !isStatusPrepared || !form.need_choose_number"
                  :state="v$.form.once_max_quantity.$error ? false : null"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="!v$.form.once_max_quantity.less.$invalid"
                >
                  需小於商品總數上限
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  :state="!v$.form.once_max_quantity.thanzero.$invalid"
                >
                  最低數量為1
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="每人最低購買數"
                label-for="once_quantity"
                v-if="false"
              >
                <b-form-input
                  id="once_quantity"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  style="width: 80px"
                  v-model="form.once_quantity"
                  :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                  :disabled="!isEdit && !isStatusPrepared || !form.need_choose_number"
                  :state="v$.form.once_quantity.$error ? false : null"
                  ></b-form-input>
                <b-form-invalid-feedback
                  :state="!v$.form.once_quantity.less.$invalid"
                >
                  需小於每人最高購買數
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="下單數量遞增數"
                label-for="once_increase_quantity"
                v-if="false"
              >
                <b-form-input
                  id="once_increase_quantity"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  style="width: 80px"
                  v-model="form.once_increase_quantity"
                  :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                  :disabled="!isEdit && !isStatusPrepared || !form.need_choose_number"
                  :state="v$.form.once_increase_quantity.$error ? false : null"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="分店選擇"
                v-if="false"
              >
                <b-form-radio-group
                  id="need_choose_branch"
                  v-model="form.need_choose_branch"
                  name="need_choose_branch"
                  :disabled="!isEdit"
                >
                  <b-form-radio :value="true">需分店選擇</b-form-radio>
                  <b-form-radio :value="false">不需分店選擇</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <h5 class="mb-2 font-weight-bold">
                下單後動作
              </h5>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="貼標"
                v-if="false"
              >
                <b-form-tags
                  input-id="tags"
                  v-model="form.tags"
                  placeholder="輸入標籤"
                  size="lg"
                  tag-variant="dark"
                  class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                  :disabled="!isEdit || !isDraftGroupbuying"
                  remove-on-delete
                  no-add-on-enter
                  separator=" ,;"
                ></b-form-tags>
                <b-form-checkbox
                  class="mb-2 mt-2 mr-sm-2 mb-sm-0"
                  id="sync_to_cresclab"
                  v-model="form.sync_to_cresclab"
                  name="sync_to_cresclab"
                  :disabled="!isEdit"
                  v-if="false"
                >同步至漸強</b-form-checkbox>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="發放優惠券"
              >
                <b-form-select class="mb-2 col-6" v-model="form.redeem_code" :options="redeem_options" :disabled="!isEdit || !isDraftGroupbuying">
                  <b-form-select-option :value="null">--</b-form-select-option>
                </b-form-select>
                <b-form-radio v-model="form.send_coupon" value="1" :disabled="(!isEdit && !isCreate) || !form.redeem_code || !isDraftGroupbuying">下單後自動發送</b-form-radio>
                <b-form-radio v-model="form.send_coupon" value="2" :disabled="(!isEdit && !isCreate) || !form.redeem_code || !isDraftGroupbuying">結團後自動發送</b-form-radio>
                <!-- <b-form-radio v-model="form.send_coupon" value="3" :disabled="(!isEdit && !isCreate) || !form.redeem_code || !isDraftGroupbuying">手動發送</b-form-radio> -->
              </b-form-group>
              <div v-if="isEdit">
                <b-button variant="primary" class="mr-5" @click="backToList()">取消</b-button>
                <b-button variant="primary" @click="submitGroupbuying()">儲存</b-button>
              </div>
            </section>
            <section class="mb-5" v-if="step == '#status'">
              <h4 class="mb-2 font-weight-bold">
                狀態設定
              </h4>
              <div class="col-12 col-xl-6" v-if="groupbuying">
                <div class="font-weight-bold">
                  團購編號：{{ groupbuying.groupbuying_number }}
                </div>
                <div>商品名稱：{{ groupbuying.title }}</div>
                <div>商品編號：{{ form.product.sale_page_code }}</div>
                <div>狀態：{{ getStatusName(groupbuying.status) }}</div>
                <div>建立時間： {{ formatDateTime(groupbuying.created_at) }}</div>
                <div>開始時間： {{ formatDateTime(groupbuying.start_at) }}</div>
                <div>截止時間： {{ formatDateTime(groupbuying.end_at) }}</div>
              </div>
              <b-button class="mt-5" variant="primary" @click="startGroupbuying()" :disabled="!isDraftGroupbuying">確定發起團購</b-button>
            </section>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import VueDraggable from "vuedraggable";
import {
  formatISO,
  format,
  isBefore,
  set,
  subDays,
} from "date-fns";
import groupbuyApi from "@/apis/groupbuy";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
import { zh } from "vuejs-datepicker/dist/locale";
import { GROUPBUYING_TYPE } from "./utils";
import productApi from "@/apis/product";
import LoadingImage from "@/assets/images/image-loading.gif";
import { statusMapping, getStatus } from "./utils";
import redeemCodeBundleApi from "../../../apis/redeemCodeBundle";
import { loadImageData, crop } from "../StoreGroupbuying/GroupbuyingImageUtils.js";

export default {
  components: { Datepicker, VueTimepicker, VueDraggable },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      zh,
      isFetchGroup: false,
      isSubmmiting: false,
      selectedProduct: null,
      selectedGroup: null,
      groupOptions: null,
      disabledDates: { to: subDays(new Date(), 1) },
      productOption: null,
      productOptions: [],
      firstBranch: null,
      secondBranch: null,
      thirdBranch: null,
      firstLayerBranches: [],
      skuList: [],
      secondLayerBranches: [],
      thirdLayerBranches: [],
      product_sku_id: null,
      form: {
        show_price:0,
        quantity:0,
        once_max_quantity: 1, // 每人最高購買數
        once_quantity: 1, // 每人最低購買數
        once_increase_quantity: 1, // 下單數量遞增數
        is_force_confirmed: 0, // 強制結單
        max_quantity: 0, // 商品總數上限
        current_quantity: 0, // 已登記組數
        amount: 0, // 總金額
        adjust_quantity: 0, // 調整數量
        tracking_code: null,
        max_purchase_limit:0 , // 每人購買上限數
        show_total_amount: true,
        start_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        payday_period: 0,
        order: 1,
        product: {
          title: null,
          description: null,
          merchant_id: null,
          available_start_at: {
            date: new Date(),
            time: {
              HH: "23",
              mm: "59",
            },
          },
          available_end_at: {
            date: new Date(),
            time: {
              HH: "23",
              mm: "59",
            },
          },
          is_closed:0,
          is_enabled:1,
          type: 1,
          original_price:0,
          suggest_price:0,
          price:0,
          skus : [
            {
              sku_name: null,
              sku_outer_code: null,
              sku_serial_number: null,
              quantity:1,
              once_quantity:1,
              once_increase_quantity:1,
              safety_quantity:1,
              carton_quantity:1,
              original_price:0,
              suggest_price:0,
              price:0,
              once_max_quantity:1,
            }
          ]
        },
        sku: {
          sku_name: null,
          sku_outer_code: null,
          sku_serial_number: null,
          quantity:1,
          once_quantity:1,
          once_increase_quantity:1,
          safety_quantity:1,
          carton_quantity:1,
          original_price:0,
          suggest_price:0,
          price:0,
          once_max_quantity:1,
        },
        need_choose_branch: 0,
        need_choose_number: 0,
        can_order_again: 1,
        send_coupon: 1,
        tags: [],
        coupon: null,
        sync_to_cresclab: true,
        redeem_code: null,
        redeem_code_name: null,
      },
      selected_product:"1",
      product_price_show: true,
      groupbuying: null,
      boundSku: null,
      GROUPBUYING_TYPE,
      step: '',
      product_price_options: [
        { item: true, name: '顯示價格' },
        { item: false, name: '不顯示價格' },
      ],
      canClick: true,
      canUpload: true,
      canUploadQuota: 10,
      currentProductId: null,
      productImages: [],
      base64Images: [],
      groupbuyDetailInfo: null,
      redeem_options: [],
      uploadingTotal: null,
      uploadingCurrent: null,
      randomId: null,
      selectedProductImage: null,
      selectedProductImageIndex: null,
      edit_product: false,
    };
  },

  validations() {
    return {
      form: {
        title: { required },
        once_max_quantity: {
          less: () => {
            return this.form.max_quantity
              ? Number(this.form.max_quantity) >=
                  Number(this.form.once_max_quantity)
              : true;
          },
          thanzero: () => {
            return this.form.once_max_quantity > 0
          }
        },
        once_quantity: {
          less: () => {
            return this.form.once_max_quantity
              ? Number(this.form.once_max_quantity) >=
                  Number(this.form.once_quantity)
              : true;
          },
        },
        once_increase_quantity: { required },
        start_at: {
          date: {
            required,
            beforeEndAt: () => {
              const startDate = set(new Date(this.form.start_at.date), {
                hours: this.form.start_at.time.HH,
                minutes: this.form.start_at.time.mm,
              });

              const endDate = set(new Date(this.form.end_at.date), {
                hours: this.form.end_at.time.HH,
                minutes: this.form.end_at.time.mm,
              });

              return isBefore(startDate, endDate);
            },
          },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        end_at: {
          date: { required },
          time: {
            HH: { required },
            mm: { required },
          },
        },
      },
    };
  },

  async created() {
    if ("CoreGroupbuyingsEditV2" === this.$route.name) {
      this.updateFlow();
    } else if ("CoreGroupbuyingsCreateV2" === this.$route.name) {
      this.createFlow();
    } else {
      this.viewFlow();
    }
    const anchor = this.$route.hash
    if (anchor && !this.isCreate) {
      this.directToStep(anchor)
    }

    window.addEventListener("resize", this.setImageButtonHeight);
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      currentBranch: (state) => state.currentBranch,
    }),
    ...mapGetters("route", ["routeQuery"]),
    isEdit() {
      return this.$route.name === "CoreGroupbuyingsEditV2";
    },
    isCreate() {
      return this.$route.name === "CoreGroupbuyingsCreateV2";
    },
    isNotSettingProduct() {
      if (!this.groupbuying?.sku) {
        return true
      }
      if (this.step == '#product' && !this.selectedProduct) {
        return true
      }
      return false
    },
    status() {
      return this.groupbuying?.status?.substring(0, 1);
    },
    isStatusPrepared() {
      return this.status === "0";
    },
    loadingImage() {
      return LoadingImage;
    },
    title() {
      if (this.isCreate) {
        return '新增團購';
      } else if (this.isEdit) {
        return '編輯團購';
      } else {
        return '檢視團購';
      }
    },
    isDraftGroupbuying() {
      return this.groupbuying?.status == "80";
    },
    isCreateProduct() {
      return this.selected_product == '2'
    },
    isEditProduct() {
      return this.edit_product
    },
  },

  watch: {
    "edit_product": function (val) {
      if (!val && this.selectedProduct) {
        this.getProductDetail(this.currentProductId, true);
        this.form.product = this.product
        this.product_price_show = this.groupbuying.show_price
      }
    },
    "selected_product": function (val) {
      if (val == '2') {
        this.selectedProduct = null
        this.edit_product = false
        this.product_sku_id = null
        this.resetFormProduct()
      }
    },
    "product_sku_id": async function (id) {
      if (!id) {
        this.currentProductId = null
        this.product = null
        this.productImages = []
        this.selectedProduct = null
        return;
      }
      this.selectedProduct = this.skuList.find((s) => s.id === id)
      this.currentProductId = this.selectedProduct.product.id
      await this.getProductDetail(this.currentProductId, true);
      this.form.product = this.product
      this.form.sku = this.selectedProduct
      if (this.product.title == this.selectedProduct.sku_name || this.selectedProduct.sku_name == '-1:-1') {
        this.form.sku.sku_name = ''
      }
    },
    "form.can_order_again": function (value) {
      if (value == '1') {
        this.form.max_purchase_limit = 0
      } else {
        this.form.max_purchase_limit = 1
      }
    },
    async images(val) {
      if (!val.length) {
        return;
      }
      const last = val[val.length - 1];
      if (last) {
        this.canClick = false;
        if (last.startsWith("data:image/jpeg;base64")) {
          await this.handleImagesChange(last);
          this.canClick = true;
        } else {
          this.getProductDetail(this.currentProductId);
          await productApi.sortImages(this.currentProductId, {
            image_urls: this.productImages,
          });
          this.canClick = true;
        }
      } else {
        await productApi.sortImages(this.currentProductId, {
          image_urls: [],
        });
        this.canClick = true;
      }
    },
  },
  methods: {
    formatDateTime(time) {
      return format(new Date(time), 'yyyy-MM-dd HH:mm')
    },
    getSkuOptionName(sku) {
      if (sku.product.title == sku.sku_name) {
        return sku.sku_name
      }
      if (sku.sku_name == '-1:-1') {
        return sku.product.title
      }
      return sku.product.title + ' ' + sku.sku_name
    },
    resetFormProduct() {
      this.form.product = {
          title: null,
          description: null,
          merchant_id: null,
          available_start_at: {
            date: new Date(),
            time: {
              HH: "23",
              mm: "59",
            },
          },
          available_end_at: {
            date: new Date(),
            time: {
              HH: "23",
              mm: "59",
            },
          },
          is_closed:0,
          is_enabled:1,
          type:'groupbuying',
          original_price:0,
          suggest_price:0,
          price:0,
          need_create_sale_page_code: 1,
          skus : [
            {
              sku_name: null,
              sku_outer_code: null,
              sku_serial_number: null,
              quantity:1,
              once_quantity:1,
              once_increase_quantity:1,
              safety_quantity:1,
              carton_quantity:1,
              original_price:0,
              suggest_price:0,
              price:0,
              once_max_quantity:1,
            }
          ]
        }
        this.form.sku = {
          sku_name: null,
          sku_outer_code: null,
          sku_serial_number: null,
          quantity:1,
          once_quantity:1,
          once_increase_quantity:1,
          safety_quantity:1,
          carton_quantity:1,
          original_price:0,
          suggest_price:0,
          price:0,
          once_max_quantity:1,
        }
        this.product_price_show = false
    },
    goToStep(step) {
      if (this.isCreate) {
        return;
      } else {
        if (this.step == step) {
          return;
        }
        if (step != '#product' && this.isNotSettingProduct) {
          if (step == '') {
            this.step = step
            this.$router.replace({ hash: this.step })
          }
          return;
        }
        this.step = step
        this.$router.replace({ hash: this.step })
      }
    },
    async getProductDetail(id) {
      const { data } = await productApi.getProduct(id);
      this.product = data.data;
      if (this.product) {
        this.productImages = this.product.images;
      }
    },
    backToList() {
      this.$router.push({ name: "CoreGroupbuyingsList", query: this.routeQuery });
    },
    async submitGroupbuying() {
      await this.handleSubmitGroupbuy()
    },
    async submitProduct() {
      if (this.selected_product == "1") {
        if (this.edit_product) {
          await this.updateProduct()
        } else {
          await this.bindProductSku()
        }
      } else {
        const status = await this.createProduct()
        if (!status) {
          return;
        }
        await this.bindProductSku()
      }
    },
    async updateProductPriceShow() {
      if (this.product_price_show != this.groupbuying.show_price) {
        const form = {
          show_price: this.product_price_show,
          start_at: format(
            set(new Date(this.form.start_at.date), {
              hours: Number(this.form.start_at.time.HH),
              minutes: Number(this.form.start_at.time.mm),
              seconds: 0,
            }),
            "yyyy-MM-dd HH:mm:ss"
          ),
          end_at: format(
            set(new Date(this.form.end_at.date), {
              hours: this.form.end_at.time.HH,
              minutes: this.form.end_at.time.mm,
              seconds: 59,
            }),
            "yyyy-MM-dd HH:mm:ss"
          ),
        }
        const { data } = await groupbuyApi.coreUpdateGroupbuying(
          this.groupbuying.id,
          form
        );
        this.groupbuying = data.data
      }
    },
    formatToDatetimeString(datetime) {
      return formatISO(
        set(new Date(datetime.date), {
            hours: Number(datetime.time.HH),
            minutes: Number(datetime.time.mm),
            seconds: 0,
          },
        )
      )
    },
    async updateProduct() {
      this.$swal({
        text: "請問是否修改商品資料？",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '是',
        cancelButtonText: '否'
      }).then(async (result) => {
        try {
          if (result.value) {
            await productApi.updateProduct(this.currentProductId, this.productPayload());
            await this.updateProductPriceShow()
            await this.bindProductSku()
          }
        } catch (error) {
          console.log(error)
          this.$swal({
            type: "error",
            html: error.response.data.message,
          });
        }
      })
    },
    productPayload() {
      const product = this.form.product
      product.suggest_price = this.form.product.original_price
      if (!this.edit_product) {
        product.available_start_at = this.formatToDatetimeString(this.form.product.available_start_at)
        product.available_end_at = this.formatToDatetimeString(this.form.product.available_end_at)
      } else {
        delete product.merchant_id
      }
      // 一些預設值
      if (this.form.sku.sku_name) {
        product.skus[0].sku_name = this.form.sku.sku_name
      } else {
        product.skus[0].sku_name = this.form.product.title
      }
      product.skus[0].quantity = this.form.sku.quantity
      product.skus[0].original_price = this.form.product.original_price
      product.skus[0].sku_serial_number = this.form.sku.sku_serial_number
      product.skus[0].sku_outer_code = this.form.sku.sku_outer_code
      product.skus[0].suggest_price = this.form.product.original_price
      product.skus[0].price = this.form.product.price

      return product;
    },
    async createProduct() {
      if (!this.form.product.title) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "商品名稱為必填",
        });
        return false;
      }
      try {
        const data = await productApi.createProduct(this.productPayload());
        await this.updateProductPriceShow()
        await this.getProductSkus()
        this.product_sku_id = data.data.data.skus[0].id
        //更新商品列表
        this.resetFormProduct()
        this.selected_product = '1'
        return true;
      } catch (error) {
        console.error(error);
      }
    },
    // 上傳中的時候，不要讓他作任何動作，不可移動照片
    checkMove() {
      return this.canClick;
    },

    // 因為 image 寬度是動態算出來的（需要三個並列所以除三等份），而這個加入照片的寬度沒有照片支撐，需要先算出目前的寬度然後直接設為高度
    setImageButtonHeight() {
      const addImageButton = document.querySelector("#addImageButton");
      if (addImageButton) {
        addImageButton.style.height = addImageButton.clientWidth + "px";
      }
    },

    async handleImagesChange(imageBase64) {
      try {
        await productApi.uploadImageByBase64(
          this.currentProductId,
          imageBase64
        );

        this.getProductDetail(this.currentProductId, true);
      } catch (err) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "上傳圖片失敗",
        });
      }
    },
    deleteImage(index) {
      this.productImages.splice(index, 1);
      this.sortImages();
    },
    createFlow () {
      this.getProductSkus();
      this.fetchRedeemCodeBundles()
    },

    viewFlow () {
      let promise1 = this.getProductSkus();
      let promise2 = this.getGroupbuying();
      Promise.all([promise1, promise2])
      .then(() => {
        if (this.form.redeem_code) {
          this.redeem_options = [
            {
            "text": this.form.redeem_code_name,
            "value": this.form.redeem_code
            }
          ]
        }

        if (!this.groupbuying.sku) {
          return;
        }
        const boundSku = this.skuList.find((sku) => {
          return sku.id == this.groupbuying.sku.id
        })

        if (! boundSku) {
          this.$swal.fire({ title: "此團購商品已刪除, 請重新選擇", type: "error" });
          return;
        }

        this.boundSku = boundSku;
        this.product_sku_id = boundSku.id;
      })
    },

    updateFlow () {
      let promise1 = this.getProductSkus();
      let promise2 = this.getGroupbuying();

      Promise.all([promise1, promise2])
      .then(() => {
        this.fetchRedeemCodeBundles()
        if (!this.groupbuying.sku) {
          return;
        }
        const boundSku = this.skuList.find((sku) => {
          return sku.id == this.groupbuying.sku.id
        })

        if (! boundSku) {
          this.$swal.fire({ title: "此團購商品已刪除, 請重新選擇", type: "error" });
          return;
        }

        this.boundSku = boundSku;
        this.product_sku_id = boundSku.id;
      })
    },

    async getGroupbuying() {
      try {
        const { data } = await groupbuyApi.getCoreGroupbuying(
          this.$route.params.groupbuyID
        );

        const {
          type,
          max_quantity,
          once_max_quantity,
          once_quantity,
          once_increase_quantity,
          start_at,
          end_at,
          payday_period,
          tracking_code,
          is_force_confirmed,
          current_quantity,
          amount,
          order,
          status,
          title,
          description,
          order_message,
          success_message,
          need_choose_number,
          can_order_again,
          need_choose_branch,
          show_price,
          quantity,
          adjust_quantity,
          sku,
          created_at,
          tags,
          redeem_code_bundle,
          max_purchase_limit,
          show_total_amount,
        } = data.data;

        this.form = {
          ...this.form,
          type,
          max_quantity,
          once_max_quantity,
          once_quantity,
          once_increase_quantity,
          start_at: {
            date: new Date(start_at),
            time: {
              HH: String(getHours(new Date(start_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
            },
          },
          end_at: {
            date: new Date(end_at),
            time: {
              HH: String(getHours(new Date(end_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
            },
          },
          quantity,
          adjust_quantity,
          show_price,
          payday_period,
          tracking_code,
          is_force_confirmed,
          current_quantity,
          amount,
          order,
          status,
          title,
          description,
          order_message,
          success_message,
          need_choose_number,
          can_order_again,
          need_choose_branch,
          tags,
          max_purchase_limit,
          show_total_amount,
        };

        if (sku) {
          this.form.sku = sku
        }

        if (redeem_code_bundle) {
          this.form.redeem_code = redeem_code_bundle.id
          this.form.send_coupon = redeem_code_bundle.issue_type
          this.form.redeem_code_name = redeem_code_bundle.code_name
        }

        this.product_price_show = show_price

        this.groupbuying = data.data;
        this.groupbuying.created_at = format(new Date(created_at), "yyyy-MM-dd HH:mm")
        this.groupbuying.start_at = format(new Date(start_at), "yyyy-MM-dd HH:mm")
        this.groupbuying.end_at = format(new Date(end_at), "yyyy-MM-dd HH:mm")
      } catch (err) {
        console.log("err", err);
      }
    },

    async getProductSkus() {
      try {
        const { data } = await groupbuyApi.getAllSkus();
        this.skuList = data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async bindProductSku() {
      const param = {
        product_sku_id: this.product_sku_id
      };
      await groupbuyApi.bindSku(this.groupbuying.id, param);
      await this.getGroupbuying()
      this.$swal
        .fire({
          type: "success",
          text: "更新成功",
        })
    },
    async handleSubmitGroupbuy() {

      const form = {
        ...this.form,
        start_at: format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
        end_at: format(
          set(new Date(this.form.end_at.date), {
            hours: this.form.end_at.time.HH,
            minutes: this.form.end_at.time.mm,
            seconds: 59,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
      };
      const result = await this.v$.$validate();
      console.log(this.v$.$errors);
      if (!result) {
        return;
      }
      if (this.$route.name === "CoreGroupbuyingsEditV2") {
        this.handleCoreUpdateGroupbuy(form);
      } else {
        this.handleCoreCreateGroupbuy(form);
      }
    },

    async startGroupbuying() {
      if (!this.groupbuying.sku) {
        this.$swal.fire({
          title: "此團購並沒有商品，請先設定",
          type: "error",
        });
        return;
      }
      this.$swal({
        text: "您確定要發布團購嗎？",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '是',
        cancelButtonText: '否'
      }).then(async (result) => {
        try {
          if (result.value) {
            const payload = {
              ids: [this.groupbuying.id],
              once_max_quantity: this.form.once_max_quantity,
            }
            await groupbuyApi.startCoreGroupbuy(payload)
            this.$swal
              .fire({
                type: "success",
                text: "開團成功",
              })
              .then(() => {
                this.$router.push({ name: "CoreGroupbuyingsList", query: this.routeQuery});
              })
          }
        } catch (error) {
          this.$swal({
            type: "error",
            html: error.response.data.message,
          });
        }
      })
    },

    async handleCoreUpdateGroupbuy(form) {
      try {
        this.isSubmmiting = true;
        const { data } = await groupbuyApi.coreUpdateGroupbuying(
          this.groupbuying.id,
          form
        );
        this.$swal
          .fire({
            type: "success",
            text: "更新成功",
          })
        this.groupbuying = data.data
      } catch (error) {
        this.$swal({
          type: "error",
          html: error.response.data.message,
        });
      }
      this.isSubmmiting = false;
    },

    async handleCoreCreateGroupbuy(form) {
      try {
        this.isSubmmiting = true;
        form.status = '80';
        const { data } = await groupbuyApi.coreCreateGroupbuy(form);
        this.groupbuying = data.data
        this.$swal
          .fire({
            type: "success",
            text: "新增成功",
          })
          .then(() => {
            this.$router.push({
              name: "CoreGroupbuyingsEditV2",
              params: {
                groupbuyID: this.groupbuying.id,
              },
            })
          });
      } catch (error) {
        console.log("");
      }
      this.isSubmmiting = false;
    },
    getStatusName(status) {
      return statusMapping[getStatus(status)];
    },
    directToStep(anchor) {
      this.step = anchor
      if (anchor == '#product-setting' && this.isNotSettingProduct) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "請先選擇商品",
        }).then(() => {
          this.goToStep('#product')
        })
      }
    },
    async fetchRedeemCodeBundles() {
      try {
        const params = {
          page: 1,
          per_page: 100,
          is_before_now: true,
          take_type: 3,
        };
        if (this.selectedMerchantID) params.merchant_id = this.selectedMerchantID
        const { data } = await redeemCodeBundleApi.getRedeemCodeBundles(params);

        this.redeem_options = data.data.filter((redeem) => {
          return isBefore(new Date(), new Date(redeem.end_at))
        })
          .map((redeem)=> {
          return {
            "text": redeem.name + ' ' + format(new Date(redeem.start_at), "yyyy-MM-dd HH:mm") + ' ~ ' + format(new Date(redeem.end_at), "yyyy-MM-dd HH:mm"),
            "value": redeem.id
          }
        });
      } catch (error) {
        console.log("");
      }
    },
    openFileSelector() {
      this.$refs.input.click()
    },
    dragEnd() {
      this.sortImages();
    },
    async sortImages(isForce = false) {
      if (this.canClick === false && !isForce) return;

      this.canClick = false;

      await productApi.sortImagesViaIds(this.currentProductId, {
        image_ids: this.productImages.map((image) => image.id),
      });

      this.canClick = true;
    },
    uploadingText() {
      if (this.uploadingTotal == null) {
        return '更新中...';
      } else {
        return `上傳中...<br>(${this.uploadingCurrent}/${this.uploadingTotal})`;
      }
    },
    async loadImage(event) {
      this.selectedProductImage = null;
      this.selectedProductImageIndex = null;

      // 這裡負責處理多圖上傳
      this.canClick = false;
      const files = [...event.target.files];

      let _this = this;

      this.uploadingTotal = files.length;

      for (let i = 0; i < files.length; i ++) {
        this.uploadingCurrent = i + 1;
        let file = files[i];

        if (_this.productImages.length >= 10) {
          _this.$swal.fire({
            title: "提醒",
            type: "warning",
            text: "圖片最多只限上傳10張",
          });
          break;
        }

        let imageBase64 = await loadImageData(file);
        imageBase64 = await this.resizeOriginalImage(imageBase64);

        let croppedCanvas = await crop(imageBase64, 1 / 1);
        let croppedBase64 = croppedCanvas.toDataURL('image/jpeg', 0.5);

        try {
          // 送上後端
          await productApi.uploadProductImages(
            _this.currentProductId,
            {
              original_image: imageBase64,
              cropped_image: croppedBase64,
            }
          );

          _this.productImages.push({
            pic_url: croppedBase64
          });
        } catch (error) {
          console.log(error);
          if (error.response.data.message) {
            _this.$swal.fire({
              title: "上傳失敗",
              type: "error",
              text: error.data.response.message,
            });
          }
        }
      }

      this.uploadingTotal = null;
      this.uploadingCurrent = null;

      this.canClick = true;
    },
    // 把原圖縮小
    async resizeOriginalImage(imageBase64) {
      // create an off-screen canvas
      var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      let originalImage = await this.base64toImage(imageBase64);

      let width = originalImage.width;
      let height = originalImage.height;
      console.log(width, height);
      if (width > 1400) {
        let ratio = 1400 / width;
        width = width * ratio;
        height = height * ratio
      }

      canvas.width = width;
      canvas.height = height;

      // draw source image into the off-screen canvas:
      ctx.drawImage(originalImage, 0, 0, width, height);

      return canvas.toDataURL('image/jpeg', 0.6);
    },
    async base64toImage(base64) {
      return new Promise((resolve) => {
        var image = new Image();
        image.onload = function() {
          resolve(image);
        };
        image.src = base64;
      });
    },
  },
};
</script>

<style>
/* Default override (not using "append-to-body") */
.vdp-datepicker input.is-invalid,
.vue__time-picker input.is-invalid {
  border: 1px solid #dc3545 !important;
}

.vdp-datepicker input:disabled,
.vue__time-picker input:disabled {
  background-color: #e9ecef !important;
  color: #212529 !important;
}

.auto-complete {
  background-color: palegreen;
  height: 30px;
}

.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: calc((100% / 3) - 0.75rem * 2);
  border: 1px solid #ddd;
  margin: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-cropped-image {
  width: 100%;
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.add-image-icon {
  font-weight: 800;
  margin-bottom: 8px;
}

.s-file-input {
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
}

.s-list-item-btn-menu {
  position: absolute;
  bottom: -35px;
  display: flex;
  flex-wrap: wrap;
}

.s-list-item-btn-menu .btn {
  padding: 5px 5px;
}

.s-radio-text {
  font-size: 0.875rem;
}

</style>
