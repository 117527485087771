export const getStatus = (status) => {
  return status?.substring(0, 1);
};

export const GROUPBUYING_STATUS = {
  WAITING: "0",
  GOING: "1",
  CONFIRMED: "2",
  FINISHED: "3",
  PENDING: "4",
  CANCELLED: "5",
  DRAFT: "8",
  DELETED: "9",
};

export const statusMapping = {
  0: "準備中",
  1: "開團中",
  2: "已結單",
  3: "待取貨",
  4: "暫停收單",
  5: "未成團",
  6: "已完成",
  8: "草稿",
  9: "刪除",
};

export const GROUPBUYING_TYPE = {
  NORMAL: 1,
  MAIN: 2, // 總公司團購
  SCAN: 3, //掃描
};
